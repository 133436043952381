import { UserLinkType } from './users.types';
import { UserKpiStatistics } from './kpi.types';

export const DAILY_CALLS_KEY = 'dailyCalls';
export const WEEK_CALLS_KEY = 'weekCalls';

export const STAT_TYPE_BY_DAY = 1;
export const STAT_TYPE_BY_WEEK = 2;

export type STAT_CHART_TYPES = 1 | 2;

export type UserStatistics = {
    dailyCalls: number;
    weekCalls: number;
    dailyCallsListened: number;
    weekCallsListened: number;
    avitoDailyCalls: number;
    problemEstatesCount: number;
    exportEstateErrorsCount: number;
    kpis: UserKpiStatistics[];
};

export type UserStatisticsTable = UserStatistics & {
    weekShowings: number;
    weekMeetingEvents: number;
    exclusives: number;
    cianCount: number;
    weekCianCreated: number;
    weekCallsToEstate: number;
    weekSmaShares: number;

    major_user: UserLinkType;
    major_user_id: number;

    enable: boolean;
};

export type UserStatisticsFilter = {
    group_id: number[];
    major_user_id: number[];
};

export type ChartStatistics = {
    date: string;
    count: number;
};

export type ChartGlobalStatistics = {
    date: string;
    cianCount: number;
    weekCianCreated: number;
    callsOutgoingCount: number;
    callsIncomingCount: number;
    openDealsCount: number;
    callsToEstateCount: number;
    showingsCount: number;
    usersCount: number;
};

export type UserKpi = {
    major_user: UserLinkType;
    major_user_id: number;

    exclusives: number;
    cianCount: number;
    openDeals: number;
    closeDeals: number;
    dealsAmount: number;
    userCreateTime: number;
    monthCalls: number;
    monthCallsToEstate: number;
    monthShowings: number;
    kpi: number;
};
