import React from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Grid from '~ui/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import estateL10n from '~/L10n/estate.L10n';

const matchHousesCount = (housesLength: number): string => {
    if (housesLength === 1) {
        return '1 корпус';
    } else if (housesLength <= 4) {
        return `${housesLength} корпуса`;
    }
    return `${housesLength} корпусов`;
};

const LifeComplexInfrastructureAbout = ({ newbuilding_id }: { newbuilding_id: number }) => {
    const newbuilding = lifeComplexStore.getItem(newbuilding_id).item;

    if (!newbuilding) {
        return (
            <Paper style={{ padding: '0 1rem' }}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Paper>
        );
    }

    const {
        houses,
        lifeComplexClass,
        floorsCount,
        materialType,
        ceilingHeight,
        decorations,
        parkingTypes,
        buildYear,
        readyQuarter
    } = newbuilding;

    return (
        <Paper style={{ padding: '0 1rem' }}>
            <Grid columns={3} alignItems="flex-start">
                {buildYear && (
                    <Grid.Column>
                        <Typography variant="subtitle1" color="textSecondary">
                            Срок сдачи
                        </Typography>
                        <Typography variant="body1">
                            {buildYear}
                            {readyQuarter && `, ${estateL10n.NEWBUILDING_READY_QUARTER_FIND(readyQuarter)} кв`}
                        </Typography>
                    </Grid.Column>
                )}
                {lifeComplexClass && (
                    <Grid.Column>
                        <Typography variant="subtitle1" color="textSecondary">
                            Класс
                        </Typography>
                        <Typography variant="body1">{estateL10n.NEWBUILDING_CLASS_FIND(lifeComplexClass)}</Typography>
                    </Grid.Column>
                )}
                {floorsCount && (
                    <Grid.Column>
                        <Typography variant="subtitle1" color="textSecondary">
                            Этажность
                        </Typography>
                        <Typography variant="body1">{floorsCount}</Typography>
                    </Grid.Column>
                )}
                <Grid.Column>
                    <Typography variant="subtitle1" color="textSecondary">
                        Корпусов
                    </Typography>
                    <Typography variant="body1">{matchHousesCount(houses.length)}</Typography>
                </Grid.Column>

                {materialType && (
                    <Grid.Column>
                        <Typography variant="subtitle1" color="textSecondary">
                            Тип дома
                        </Typography>
                        <Typography variant="body1">{estateL10n.HOUSE_MATERIAL_TYPES_FIND(materialType)}</Typography>
                    </Grid.Column>
                )}
                {ceilingHeight && (
                    <Grid.Column>
                        <Typography variant="subtitle1" color="textSecondary">
                            Высота потолков
                        </Typography>
                        <Typography variant="body1">{ceilingHeight.toLocaleString('ru')} м</Typography>
                    </Grid.Column>
                )}
                {decorations && (
                    <Grid.Column>
                        <Typography variant="subtitle1" color="textSecondary">
                            Варианты отделки
                        </Typography>
                        <Typography variant="body1">{decorations}</Typography>
                    </Grid.Column>
                )}
                {parkingTypes && (
                    <Grid.Column>
                        <Typography variant="subtitle1" color="textSecondary">
                            Парковка
                        </Typography>
                        <Typography variant="body1">{parkingTypes}</Typography>
                    </Grid.Column>
                )}
            </Grid>
        </Paper>
    );
};

export default observer(LifeComplexInfrastructureAbout);
