import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import ItemStorePrototype, { ItemKey, TItemExtended } from '~/stores/prototypes/ItemStore.prototype';
import { HandleChangeType } from '~/components/Base/EditField';

type HandleChangeTextField<T> = (data: { value: T }) => void;

const useStoreEditingField = <T extends TItemExtended, K extends keyof T>(
    store: ItemStorePrototype<T>,
    item_id: ItemKey,
    fieldName: K,
    initialValue: T[K] | null
): [T[K] | null, HandleChangeType, (value: T[K]) => void] => {
    const [value, setValue] = useState<T[K] | null>(initialValue);

    useEffect(() => {
        store.setEditingItem(item_id, { [fieldName]: value } as any);
    }, [value]);

    const handleChangeText = useCallback((event: SyntheticEvent, { value }: { value: T[K] }) => setValue(value), []);

    return [value, handleChangeText, setValue];
};

export default useStoreEditingField;
