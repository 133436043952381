import React, { Fragment } from 'react';
import { AvitoMessage, ChatListType, ChatMessage as ChatMessageType } from '~/types/chat.types';
import { UserLinkState } from '../Lists/Users/UserLink';
import isEqual from '~/common/isEqual';

import ChatMessage from './ChatMessage';
import { CianMessage } from '~/types/cian_chat.types';

export const ChatMessagesList = React.memo(
    ({
        messages,
        chatType,
        user_id
    }: {
        chatType: ChatListType;
        messages: (ChatMessageType | AvitoMessage | CianMessage)[];
        user_id: number;
    }) => {
        const isFromAds = ['avito', 'cian'].includes(chatType);

        return (
            <>
                {messages.map(({ message_id, message, createTime, sender, messageType, deliveryTime, readTime }) => (
                    <ChatMessage
                        key={message_id}
                        avatar={sender.avatarUrl}
                        message={message}
                        reverse={(isFromAds && sender['user_id']) || sender['user_id'] === user_id}
                        time={createTime}
                        name={sender['name'] || `${sender['firstName']} ${sender['lastName']}`}
                        hrefTo={isFromAds ? (sender['user_id'] !== null ? UserLinkState(sender['user_id']) : sender['userUrl']) : null}
                        showName={chatType === 'cian'}
                        deliveryTime={deliveryTime}
                        readTime={readTime}
                    />
                ))}
            </>
        );
    },
    isEqual
);

export default ChatMessagesList;
