import config from '~/config/ui.config';

export type CellCallMetadata = {
    call_id: number;
    externalId?: string;
    outer_id?: string;
    downloadLink?: string;
    answerTime: number;
    hasListened: boolean;
};

export const matchDownloadUrl = (callMetadata: CellCallMetadata): string | null => {
    let { call_id, externalId, outer_id, downloadLink, answerTime } = callMetadata;

    if (outer_id?.indexOf('out_') === 0 || outer_id?.indexOf('in_') === 0) {
        externalId = outer_id;
    }

    return (
        downloadLink || (externalId && answerTime ? `${config.serverUrl}/call/download?call_id=${call_id}&external_id=${externalId}` : null)
    );
};
