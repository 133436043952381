import React from 'react';
import { observer } from 'mobx-react';
import { KPI_TYPE } from '~/types/kpi.types';

import kpiStore from '~/stores/kpiStore';

import { SortDirection, TableCell, TableHead, TableRow } from '~ui/Table';
import Tooltip from '@material-ui/core/Tooltip';

import TableHeadPoint from './TableHeadPoint';

const POINT_KPI = 0;

const TableKpiHead = ({
    kpi_id,
    sortPoint,
    sortDirection,
    handleSortPoint
}: {
    kpi_id: number;
    sortPoint: number;
    sortDirection: SortDirection;
    handleSortPoint: (point_id: number) => void;
}) => {
    const { item } = kpiStore.getItem(kpi_id);
    const { title, points, kpiType } = item;

    return (
        <TableHead>
            <TableRow>
                <TableCell>№</TableCell>
                <TableCell>
                    {kpiType === KPI_TYPE.groupAvg ? 'Отдел среднее' : kpiType === KPI_TYPE.groupSum ? 'Отдел сумма' : 'Сотрудник'}
                </TableCell>
                {[KPI_TYPE.groupAvg, KPI_TYPE.groupSum].includes(kpiType) && (
                    <>
                        <TableCell>Руководитель</TableCell>
                        <TableCell>
                            <Tooltip title="Количество сотрудников в статистике (всего в отделе)">
                                <span>Сотр.</span>
                            </Tooltip>
                        </TableCell>
                    </>
                )}
                <TableCell
                    align="right"
                    sortDirection={sortPoint === POINT_KPI ? sortDirection : false}
                    onClick={() => handleSortPoint(POINT_KPI)}
                >
                    {title}
                </TableCell>
                {points?.map(point => (
                    <TableHeadPoint
                        key={point.point_id}
                        point={point}
                        sortPoint={sortPoint}
                        sortDirection={sortDirection}
                        setSort={handleSortPoint}
                    />
                ))}
            </TableRow>
        </TableHead>
    );
};

export default observer(TableKpiHead);
