import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';

import { CellCallMetadata, matchDownloadUrl } from '~/stores/helpers/cellCalls.helpers';

import ButtonGroup from '~/components/Base/ButtonGroup';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import Box from '@material-ui/core/Box';

const DEFAULT_RATE = 1;

type ListenOrDownloadBoxProps = {
    callMetadata: CellCallMetadata;
    showDownloadLink?: boolean;
};

const ListenOrDownloadBox = ({ callMetadata, showDownloadLink = true }: ListenOrDownloadBoxProps) => {
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [playbackRate, setPlaybackRate] = useState(DEFAULT_RATE);

    const handleSpeedChange: HandleChangeType = (event, { value }) => {
        setPlaybackRate(Number(value));
        if (audio) {
            audio.playbackRate = Number(value);
        }
    };

    let { downloadLink } = callMetadata;

    const downloadUrl = matchDownloadUrl(callMetadata);

    if (!downloadUrl) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" style={{ gap: '0.5rem' }}>
            <audio controls ref={setAudio} style={{ width: '100%' }}>
                <source src={downloadUrl} type="audio/mp3" />
            </audio>
            <div style={{ marginBottom: '8px' }}>
                Скорость&nbsp;&nbsp;
                <ButtonGroup
                    size="small"
                    buttonSet={[
                        [1, '1.0'],
                        [1.25, '1.25'],
                        [1.5, '1.5'],
                        [1.75, '1.75'],
                        [2, '2.0']
                    ]}
                    name="type"
                    value={playbackRate}
                    handleChange={handleSpeedChange}
                />
            </div>
            {showDownloadLink && (
                <a href={`${downloadUrl}${downloadLink ? '' : '&save=true'}`} title="Скачать" target="_blank" rel="noreferrer">
                    <Icon name="cloud download" />
                    Скачать
                </a>
            )}
        </Box>
    );
};

export default ListenOrDownloadBox;
