import { UserLinkType } from './users.types';
import { Color, Contact, ContactLinkType } from './contacts.types';

export const CALL_TYPE_INCOMING = 1;
export const CALL_TYPE_OUTGOING = 2;

export const USER_PHONE_TYPE_ID = 1;
export const CONTACT_PHONE_TYPE_ID = 2;

export type CellCallLinkType = {
    call_id: number;
    direction: number | null;
    phone: string | null;
};

export type CellCallInBase = CellCallLinkType & {
    externalId?: string;
    outer_id?: string;
    abonent: string | null;
    startTime: number;
    answerTime: number | null;
    releaseTime: number | null;
    major_user_id?: number;
    type: number | null;
    item_id: number | null;
    hasListened: boolean;

    enable: boolean;

    estate_id?: number;
    source_id?: number;
    comment?: string;

    sourceTitle?: string;
    color?: Color;

    downloadLink?: string;

    reason: string | null;
    assign_user_id: number | null;
};

export type CellCallEstateInBase = {
    call_id: number;
    estate_id: number;
    wish_id: number;
    source_id: number;
    comment: string | null;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type CellCallEstateType = CellCallEstateInBase & {
    setMajorUsersFromEstate?: boolean;
    changeOnlyCallEstate?: boolean;
    contact_id?: number;
    contact?: Partial<Contact>;
};

export type CellCall = CellCallInBase & {
    major_user?: UserLinkType;
    itemContact?: ContactLinkType;
    tags_ids?: number[];
    itemUser?: UserLinkType;
    assignUser?: UserLinkType;
};

export type CellCallFilter = {
    enable: boolean;
    major_user_id?: number[];
    group_id?: number[];
    deltaStartTime?: number;
    direction: number;
    source_id?: number;
    tags_ids?: number[];
    tagsTogether?: boolean;
    search: string;
    hasListened?: boolean;
    durationFromSec: number | null; // -1 - пропущенные, null - все, 0 - Состоявшиеся, 30, 60
};

export type CallSource = {
    source_id: number;
    title: string;
    color: Color;
    description: string;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type CellOperatorType = {
    cell_operator_id: number;
    database: string;
    operator: number;
    name: string;
    token: string;
    externalUserId: string | null;
    url: string | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};
