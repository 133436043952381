export const ACCESS_NONE = 'none';
export const ACCESS_SELF = 'self';
export const ACCESS_GROUP = 'group';
export const ACCESS_OFFICE = 'office';
export const ACCESS_COMPANY = 'company';

export const ACCESS_READ = 'read';
export const ACCESS_WRITE = 'write';
export const ACCESS_DELETE = 'delete';

const accessPermissionsMap = [ACCESS_NONE, ACCESS_SELF, ACCESS_GROUP, ACCESS_OFFICE, ACCESS_COMPANY] as const;

export type AccessPermission = typeof accessPermissionsMap[number];

export type Access = {
    readonly access_id: number;
    title: string;
    modules: AccessModule[];
    enableCommittingExport: boolean;
    enableCommittingPremium: boolean;
    enableApplyingPremium: boolean;
    enableCommittingExclusives: boolean;
    maxEnableDebt: number | null;
    export_moderator_id: number | null;
    chiefIsModerator: boolean | null;
    includedInStatistics: boolean | null;
    enable: boolean;
};

export type AccessModule = {
    name: string;
    access: AccessModeType;
};

export type UserModuleAccess = {
    title: string;
    name: string;
    modes: Array<AccessModes>;
    permissions: Array<AccessPermission>;
};

const accessModesMap = [ACCESS_READ, ACCESS_WRITE, ACCESS_DELETE] as const;

export type AccessModes = typeof accessModesMap[number];

export type AccessModeType = {
    read: AccessPermission;
    write: AccessPermission;
    delete: AccessPermission;
};
