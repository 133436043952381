import React from 'react';
import Time from '~/components/Base/Time';
import TwoAvatars from '~/components/Base/ui/TwoAvatars';

import { AvitoChatTitle, ChatListType, CianChatTitle } from '~/types/chat.types';

const AvitoCianCell = ({
    chat,
    active,
    onSelectUser
}: {
    chat: AvitoChatTitle | CianChatTitle;
    active: boolean;
    onSelectUser: (chatType: ChatListType, avito_chat_id: number, estate_id: number) => void;
}) => {
    let user = 'avito_chat_id' in chat ? chat.avitoUser : chat.cianUser;
    let chat_id = 'avito_chat_id' in chat ? chat.avito_chat_id : chat.cian_chat_id;

    const { estate, lastMessage, countUnreadMessages } = chat;

    return (
        <div
            className={`crm-Chat__user_item  ${active ? 'active' : ''}`}
            onClick={onSelectUser.bind(this, 'avito_chat_id' in chat ? 'avito' : 'cian', chat_id, estate.estate_id)}
        >
            <div className="crm-Chat__user_row">
                <div className="crm-Chat__item_avatar">
                    <div className="crm-Chat__item_avatar_mode">
                        <TwoAvatars
                            mainAvatarUrl={estate.avatarUrl}
                            mainTitle={estate.address}
                            secondAvatarUrl={user.avatarUrl}
                            secondTitle={user.name}
                        />
                    </div>
                </div>

                <div className="crm-Chat__info">
                    <span className="crm-Chat__info_name" style={{ fontSize: '0.925em' }}>
                        {estate.address?.substring(0, 25) + '...'}
                    </span>
                    <div className="crm-Chat__info_des">{lastMessage.message?.substring(0, 25) + '...'}</div>
                    <div className="crm-Chat__info_time">
                        <Time time={lastMessage.createTime} />
                    </div>
                </div>

                <div className="crm-Chat__unread">
                    {countUnreadMessages > 0 && <div className="crm-Chat__unread_badge">{countUnreadMessages}</div>}
                </div>
            </div>
        </div>
    );
};

export default React.memo(AvitoCianCell);
