import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';

import { reportOpenCellCallDownload } from '~/api/cell/cellCallsApi';
import authStore from '~/stores/authStore';
import { CellCallMetadata, matchDownloadUrl } from '~/stores/helpers/cellCalls.helpers';

import Divider from '~ui/Divider';
import Checkbox from '~ui/Checkbox';
import { useToggle } from '~ui/Modal';

import ListenOrDownloadBox from './ListenOrDownloadBox';
import RateCellCallModal from './RateCellCallModal';
import CallListensModal from './CallListensModal';

type ListenCellCallProps = {
    callMetadata: CellCallMetadata;
    major_user_id: number;
};

const ListenCellCall = (props: ListenCellCallProps) => {
    const [showModal, toggleModal] = useToggle();
    const [showListeners, toggleListenersModal] = useToggle();

    const { callMetadata, major_user_id } = props;

    const reportOpen = async () => {
        const { call_id, externalId, outer_id } = callMetadata;
        await reportOpenCellCallDownload({ call_id, externalId, outer_id });
    };

    if (callMetadata.hasListened) {
        return (
            <>
                <button onClick={toggleListenersModal}>
                    <Icon size="large" name="play circle" title="Прослушать" link color="green" />
                </button>
                {showListeners && <CallListensModal callMetadata={callMetadata} onClose={toggleListenersModal} />}
            </>
        );
    }

    if (!matchDownloadUrl(callMetadata)) {
        return <Icon size="large" name="play circle" color="grey" disabled />;
    }

    return (
        <>
            {!showModal && (
                <Popup
                    trigger={
                        <Icon size="large" name="play circle" title="Прослушать" link color={callMetadata.hasListened ? 'green' : 'blue'} />
                    }
                    content={
                        <>
                            <ListenOrDownloadBox callMetadata={callMetadata} />
                            {authStore.currentUserId !== major_user_id && (
                                <>
                                    <Divider />
                                    <Checkbox label="Прослушал(а)" onClick={toggleModal} />
                                </>
                            )}
                        </>
                    }
                    on="click"
                    position="top right"
                    onOpen={reportOpen}
                    wide="very"
                />
            )}
            {showModal && <RateCellCallModal callMetadata={callMetadata} onClose={toggleModal} />}
        </>
    );
};

export default ListenCellCall;
