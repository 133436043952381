import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import contactStore from '~/stores/contactStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import CellCallTableBody from '~/components/Lists/CellCalls/CellCallTableBody';
import FindCallModal from './FindCallModal';

import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';

import LoaderAwait from '~/components/Base/LoaderAwait';
import { WarningMessage } from '~ui/Message';
import { useToggle } from '~ui/Modal';
import Table, { TableRow, TableHead, TableCell } from '~ui/Table';
import Button from '~ui/Button';

type EstateCallsModeProps = {
    estate_id: number;
};

const EstateIncomingCallsMode = ({ estate_id }: EstateCallsModeProps) => {
    useEffect(() => {
        estateStore.callsByEstate(estate_id);

        const { contact_id } = estateStore.getItem(estate_id).item || {};
        if (contact_id) {
            contactStore.fetchItem(contact_id);
        }
    }, [estate_id]);

    const [showCallModal, toggleCallModal] = useToggle();

    const {
        property: { calls, loadingCalls }
    } = estateStore.getItem(estate_id);

    return (
        <>
            <Button onClick={toggleCallModal} startIcon={<PhoneCallbackIcon />} size="small" color="primary" variant="contained">
                Прикрепить звонок
            </Button>

            {calls && calls.length > 0 && (
                <Table size="small" style={{ marginTop: '1rem' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            <TableCell>Источник</TableCell>
                            <TableCell>Клиент</TableCell>
                            <TableCell />
                            <TableCell>Сотрудник</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>

                    <CellCallTableBody cellCallList={calls} hideEstate showSource />
                </Table>
            )}

            {calls && calls.length === 0 && <WarningMessage>У данного объекта нет звонков</WarningMessage>}

            <LoaderAwait active={loadingCalls} size="large" />

            {showCallModal && <FindCallModal item_id={CREATING_ITEM_ID} estate_id={estate_id} handleClose={toggleCallModal} />}
        </>
    );
};

export default observer(EstateIncomingCallsMode);
