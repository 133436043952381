import React, { Fragment } from 'react';
import { Table, Popup, Icon, Label, Checkbox } from 'semantic-ui-react';

import { ESTATE_BASE_MLS, EstateTableItem, ShareEstateId } from '~/types/estate.types';
import estateL10n from '~/L10n/estate.L10n';
import PreventDblClick from '../../Base/PreventDblClick';
import UsersLinkList from '../Users/UsersLinkList';
import {
    ESTATE_BASE_MAIN,
    ESTATE_BASE_MY,
    ESTATE_BASE_OWNERS,
    ESTATE_BASE_GROUP,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL_ID,
    ESTATE_BASE_FAVORITES
} from '~/types/estate.types';
import { nl2br } from '~/api/commentsApi';

import EstateTableBase from './SubBlocks/EstateTableBase';
import EstateTableContacts from './SubBlocks/EstateTableContacts';
import OwnerTableContacts from './SubBlocks/OwnerTableContacts';
import MainMetro from './MainMetro';
import ListComments from '../Common/ListComments';
import EstateIsFavorite from './SubBlocks/EstateIsFavorite';
import EstateTableSmaButton from './SubBlocks/EstateTableSmaButton';
import EstateLinkWithPreview from './EstateLinkWithPreview';
import { EXPIRED_CALL_STATUS_ERROR, matchExpiredCallStatus } from '~/api/cell/expiredCallsApi';
import estateStore from '~/stores/estateStore';
import isEqual from '../../../common/isEqual';
import EstateTableAreaBlock from './SubBlocks/EstateTableAreaBlock';
import EstateTableFloorAndHouseBlock from './SubBlocks/EstateTableFloorAndHouseBlock';
import EstateTableTimes from './SubBlocks/EstateTableTimes';
import EstateTableNewbuilding from './SubBlocks/EstateTableNewbuilding';
import PriceHistoryPopup from '~/components/Items/Estate/ShowBlocks/PriceHistoryPopup';

type EstateTableBodyProps = {
    estateList: EstateTableItem[];
    simpleMode?: boolean;
    showExportMajorUser?: boolean;
    byCreateTime?: boolean;
    selectedItemsIds?: ShareEstateId[];
    toggleItemSelected?: (index: ShareEstateId) => void;
    filterBase?: string;
    loadingList?: boolean;
    isPrinting?: boolean;
    smaEstateId?: number;
    isForeign?: boolean;
};

const PrintAgentBonus = React.memo(
    ({
        agentBonusType,
        agentBonusCurrency,
        agentBonusValue
    }: {
        agentBonusType: 1 | 2 | null;
        agentBonusValue: number | null;
        agentBonusCurrency: 1 | 2 | 3;
    }) => {
        return (
            <Label color="green" size="mini" title="Бонус агенту">
                {agentBonusValue.toLocaleString()}&nbsp;
                {agentBonusType === 2 ? '%' : estateL10n.CURRENCY_TYPES_FIND(agentBonusCurrency)}
            </Label>
        );
    }
);

const EstateTableBody = ({
    estateList,
    simpleMode,
    showExportMajorUser,
    byCreateTime,
    selectedItemsIds,
    toggleItemSelected,
    filterBase,
    loadingList,
    isPrinting,
    smaEstateId,
    isForeign
}: EstateTableBodyProps) => (
    <Table.Body>
        {estateList.map(estate => {
            const {
                type,
                propertyType,
                description,
                estate_id,
                metros,
                address,
                houseNumberAdjusted,
                cityDistrict,
                cityDistrictType,
                roomsCount,
                totalArea,
                livingArea,
                kitchenArea,
                allRoomsArea,
                floorNumber,
                floorsCount,
                materialType,
                major_users,
                export_users,
                createTime,
                updateTime,
                price,
                currency,
                base,
                // agency,
                ownerPhones,
                ownerName,
                exportingBaseCount,
                exportingErrorsCount,
                photosCount,
                owner_source_id,
                newbuilding,
                newbuildingHouse,
                hasVideo,
                main_metro_id,
                comments,

                buildYear,
                buildingIsNotReadyYet,

                landArea,
                landAreaUnitType,

                showingsCount,
                callsCount,
                adsMessagesCount,
                isFake,
                isTop3,
                exclusiveDealId,
                enable,
                isFavorite,
                isHidden,
                isAlreadySold,
                isBooked,
                whetherIsInSma,
                lastOutgoingCallToOwnerTime,

                source,
                sourceInnerId,
                agencyName,
                agency,

                agentBonusValue,
                agentBonusCurrency,
                agentBonusType,
                priceHistory
            } = estate;

            const exportHasBaned =
                enable &&
                Boolean(exportingBaseCount) &&
                base === ESTATE_BASE_MAIN &&
                matchExpiredCallStatus(lastOutgoingCallToOwnerTime || 0, isFake) === EXPIRED_CALL_STATUS_ERROR;

            const shareId = estateStore.matchSelectedItemsFiled(estate);
            const isChecked = selectedItemsIds && Boolean(selectedItemsIds.find(item => isEqual(item, shareId)));

            const isPrintingRow = isPrinting && isChecked;

            return (
                <Fragment key={`Estate_${estate_id}`}>
                    <Table.Row warning={isBooked} negative={exportHasBaned} className={isPrintingRow ? '' : 'crm-Print__hidden'}>
                        {toggleItemSelected && (
                            <Table.Cell collapsing className="crm-Print__hidden">
                                <Checkbox onClick={() => toggleItemSelected(shareId)} checked={isChecked} />
                            </Table.Cell>
                        )}
                        <Table.Cell>
                            <EstateTableTimes
                                byCreateTime={Boolean(byCreateTime)}
                                enable={enable}
                                createTime={createTime}
                                updateTime={updateTime}
                            />
                            <div className="crm-Estate__tableBases">
                                <div className="crm-Estate__list_favorite crm-Estate__marginRight">
                                    {!smaEstateId && (
                                        <EstateIsFavorite isHidden={isHidden} isFavorite={isFavorite} estate_id={estate_id} base={base} />
                                    )}
                                    {smaEstateId && (
                                        <EstateTableSmaButton
                                            estate_id={estate_id}
                                            base={base}
                                            sma_estate_id={smaEstateId}
                                            whetherIsInSma={whetherIsInSma}
                                        />
                                    )}
                                </div>

                                <EstateTableBase
                                    base={base}
                                    agency={agency}
                                    source={source}
                                    sourceInnerId={sourceInnerId}
                                    estate_id={estate_id}
                                    exportingBaseCount={exportingBaseCount}
                                    owner_source_id={owner_source_id}
                                    isFake={isFake}
                                    exclusiveDealId={exclusiveDealId}
                                    isTop3={isTop3}
                                    agencyName={agencyName}
                                    exportingErrorsCount={exportingErrorsCount}
                                    enable={enable}
                                    lastOutgoingCallToOwnerTime={lastOutgoingCallToOwnerTime}
                                />
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <EstateLinkWithPreview
                                estate={{ type, address, estate_id, roomsCount, propertyType, enable, houseNumberAdjusted }}
                                base={base}
                                isForeign={isForeign}
                            />
                            <div>
                                <EstateTableNewbuilding newbuilding={newbuilding} simpleMode={simpleMode} />
                                {metros && <MainMetro metros={metros} main_metro_id={main_metro_id} />}
                                {(!metros || !metros.length) && cityDistrict && (
                                    <span className="crm-Estate__fieldNowrap">
                                        {cityDistrict} {cityDistrictType}
                                    </span>
                                )}
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <EstateTableAreaBlock
                                propertyType={propertyType}
                                totalArea={totalArea}
                                livingArea={livingArea}
                                kitchenArea={kitchenArea}
                                allRoomsArea={allRoomsArea}
                                landArea={landArea}
                                landAreaUnitType={landAreaUnitType}
                                roomsCount={roomsCount}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <EstateTableFloorAndHouseBlock
                                propertyType={propertyType}
                                floorsCount={floorsCount}
                                floorNumber={floorNumber}
                                materialType={materialType}
                                buildYear={buildYear}
                                buildingIsNotReadyYet={buildingIsNotReadyYet}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <span className="crm-List__monoField crm-Estate__fieldNowrap">
                                <div>
                                    {price.toLocaleString()} {estateL10n.CURRENCY_TYPES_FIND(currency)}{' '}
                                    {type === ESTATE_TYPE_RENT_ID && '/мес'}
                                    <PriceHistoryPopup priceHistory={priceHistory} />
                                </div>
                                {type === ESTATE_TYPE_SELL_ID &&
                                    Number(totalArea) > 0 &&
                                    `${Math.round(price / Number(totalArea)).toLocaleString()} за м²`}
                                {isAlreadySold && (
                                    <Fragment>
                                        <br />
                                        <Label color="red" tag size="mini" title="Собственник сообщил, что объект уде продан">
                                            продан
                                        </Label>
                                    </Fragment>
                                )}
                                {base === ESTATE_BASE_MLS && agentBonusValue && (
                                    <>
                                        <br />
                                        <PrintAgentBonus
                                            agentBonusValue={agentBonusValue}
                                            agentBonusCurrency={agentBonusCurrency}
                                            agentBonusType={agentBonusType}
                                        />
                                    </>
                                )}
                            </span>
                        </Table.Cell>
                        {!showExportMajorUser &&
                            [ESTATE_BASE_MY, ESTATE_BASE_GROUP, ESTATE_BASE_OWNERS, ESTATE_BASE_FAVORITES].includes(filterBase) && (
                                <Table.Cell>
                                    {!loadingList && (
                                        <ListComments
                                            comments={comments}
                                            item_id={estate_id}
                                            item_type={base === ESTATE_BASE_OWNERS ? 'OWNER' : 'ESTATE'}
                                        />
                                    )}
                                </Table.Cell>
                            )}
                        {!simpleMode && (
                            <Table.Cell>
                                <PreventDblClick>
                                    <EstateTableContacts
                                        base={base}
                                        major_users={major_users}
                                        photosCount={photosCount}
                                        showExportMajorUser={showExportMajorUser}
                                        hasVideo={hasVideo}
                                        description={description}
                                        showingsCount={showingsCount}
                                        callsCount={callsCount}
                                        adsMessagesCount={adsMessagesCount}
                                        lastOutgoingCallToOwnerTime={lastOutgoingCallToOwnerTime}
                                    />
                                    {base === ESTATE_BASE_OWNERS &&
                                        (!major_users || major_users.length === 0) &&
                                        ownerPhones &&
                                        ownerPhones.length > 0 && (
                                            <OwnerTableContacts estate_id={estate_id} ownerPhone={ownerPhones[0]} ownerName={ownerName} />
                                        )}
                                </PreventDblClick>
                            </Table.Cell>
                        )}
                        {simpleMode && (
                            <Table.Cell>
                                <Popup
                                    wide
                                    position="left center"
                                    size="tiny"
                                    trigger={<Icon bordered name="info" />}
                                    content={<span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />}
                                />
                            </Table.Cell>
                        )}
                        {showExportMajorUser && (
                            <Table.Cell>{export_users && export_users.length > 0 && <UsersLinkList usersList={export_users} />}</Table.Cell>
                        )}
                    </Table.Row>
                    {isPrintingRow && (
                        <Fragment>
                            {typeof description === 'string' && description.length > 0 && (
                                <Table.Row className={'crm-Print__show crm-Print__tableRow'}>
                                    <Table.Cell colSpan={7}>{description}</Table.Cell>
                                </Table.Row>
                            )}
                            <Table.Row className={'crm-Print__show crm-Print__tableRow'}>
                                <Table.Cell colSpan={7}>
                                    <span style={{ color: '#ccc' }}>Примечание</span>
                                </Table.Cell>
                            </Table.Row>
                        </Fragment>
                    )}
                </Fragment>
            );
        })}
    </Table.Body>
);

export default EstateTableBody;
