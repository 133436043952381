import React, { Fragment, PureComponent } from 'react';
import { Icon, Dropdown, Popup } from 'semantic-ui-react';
import { TableCell, TableRow, TableBody } from '~ui/Table';
import history from '~/history';

import Time from '~/components/Base/Time';
import { CellCall, CALL_TYPE_INCOMING, CONTACT_PHONE_TYPE_ID, USER_PHONE_TYPE_ID } from '~/types/cellCalls.types';
import { UserLinkType } from '~/types/users.types';

import { adjustPhoneNumber } from '~/components/Base/PhonesList';
import { secondsToMS } from '~/common/time';
import cellCallStore from '~/stores/cell/cellCallStore';
import { EstateLinkState } from '../Estate/EstateLink';

import { ESTATE_BASE_MAIN, ESTATE_TYPE_SELL } from '~/types/estate.types';
import ListTagsPopup from '../Contacts/ListTagsPopup';
import CellCallToEstateModal from './SubBlocks/CellCallToEstateModal';
import CellCallToContactEdit from './SubBlocks/CellCallToContactEdit';

import ListenCellCall from './ListenCellCall';
import UserLinkWithPreview from '../Users/UserLinkWithPreview';
import ContactLinkWithPreview from '../Contacts/ContactLinkWithPreview';

import UserLink from '../Users/UserLink';
import EstatePreview from '~/components/Items/Estate/EstatePreview';

type CellCallTableBodyProps = {
    cellCallList: Array<CellCall>;
    hideClient?: boolean;
    hideOperations?: boolean;
    hideEstate?: boolean;
    showSource?: boolean;
};

const handleOpenEstate = (estate_id: number) => {
    history.push(EstateLinkState(estate_id, ESTATE_TYPE_SELL, ESTATE_BASE_MAIN));
};

const OpenEstateButton = React.memo(({ estate_id, hideEstate }: { estate_id?: number; hideEstate?: boolean }) => {
    if (estate_id && !hideEstate) {
        return (
            <EstatePreview
                item_id={estate_id}
                base="crm"
                trigger={
                    <span className="crm-Main__pointer" onClick={handleOpenEstate.bind(null, estate_id)}>
                        <Icon name="arrow right" color="green" />
                        <Icon name="building" color="orange" />
                    </span>
                }
                textNoWrap
            />
        );
    }

    return null;
});

const CallStatus = ({
    answerTime,
    releaseTime,
    call_id,
    externalId,
    outer_id,
    downloadLink,
    startTime,
    direction,
    assignUser,
    reason,
    hasListened,
    major_user_id
}: {
    call_id: number;
    externalId?: string;
    outer_id?: string;
    startTime: number;
    answerTime: number | null;
    releaseTime: number | null;
    downloadLink?: string;
    direction: number | null;
    assignUser?: UserLinkType;
    reason: string | null;
    hasListened: boolean;
    major_user_id: number;
}) => (
    <Fragment>
        {direction && answerTime && releaseTime && (
            <Fragment>
                <ListenCellCall
                    callMetadata={{
                        call_id,
                        externalId,
                        outer_id,
                        downloadLink,
                        answerTime,
                        hasListened
                    }}
                    major_user_id={major_user_id}
                />
                <span title={`id: ${call_id}`}>{secondsToMS(Math.round((releaseTime - answerTime) / 1000))}</span>
            </Fragment>
        )}
        {direction && answerTime && !releaseTime && (
            <Fragment>
                <Icon name="phone" color="yellow" /> разговор
            </Fragment>
        )}
        {direction && !answerTime && !releaseTime && (
            <Fragment>
                <Icon name="volume control phone" color="green" /> звонок
            </Fragment>
        )}
        {direction && !answerTime && releaseTime && (
            <Fragment>
                <Icon name="close" color="red" /> без ответа{' '}
                <span style={{ color: 'red' }}>({secondsToMS(Math.round((releaseTime - startTime) / 1000))})</span>
            </Fragment>
        )}
        {direction === null && assignUser && (
            <Popup
                trigger={
                    <span>
                        <UserLink user={assignUser} />
                        <Icon name="talk" />
                    </span>
                }
                size="small"
                content={reason}
            />
        )}
    </Fragment>
);

class CellCallTableBody extends PureComponent<CellCallTableBodyProps> {
    render() {
        const { cellCallList, hideClient, hideOperations, hideEstate, showSource } = this.props;
        if (!cellCallList?.length) {
            return null;
        }

        return (
            <Fragment>
                <TableBody>
                    {cellCallList.map(
                        ({
                            call_id,
                            phone,
                            abonent,
                            direction,
                            downloadLink,
                            externalId,
                            startTime,
                            answerTime,
                            releaseTime,
                            itemUser,
                            itemContact,
                            type,
                            item_id,
                            major_user,
                            estate_id,
                            outer_id,
                            hasListened,
                            major_user_id,

                            color,
                            sourceTitle,
                            comment,
                            tags_ids,
                            assignUser,
                            reason
                        }) => {
                            return (
                                <Fragment key={call_id}>
                                    <TableRow negative={direction === null}>
                                        <TableCell>
                                            <Time time={startTime} />

                                            <OpenEstateButton estate_id={estate_id} hideEstate={hideEstate} />

                                            {type !== USER_PHONE_TYPE_ID &&
                                                (!hideOperations || hideClient) &&
                                                direction === CALL_TYPE_INCOMING && (
                                                    <CellCallToEstateModal estate_id={estate_id} item_id={item_id} call_id={call_id} />
                                                )}
                                        </TableCell>
                                        {showSource && (
                                            <TableCell>
                                                <Icon name="database" color={color} />
                                                {sourceTitle}
                                            </TableCell>
                                        )}
                                        {!hideClient && (
                                            <Fragment>
                                                <TableCell>
                                                    <b>
                                                        {!type && phone && <a href={`tel:+${phone}`}>{adjustPhoneNumber(phone)}</a>}
                                                        {type === CONTACT_PHONE_TYPE_ID && itemContact && (
                                                            <ContactLinkWithPreview contact={itemContact} />
                                                        )}
                                                        {type === USER_PHONE_TYPE_ID && itemUser && (
                                                            <UserLinkWithPreview user={itemUser} icon={true} />
                                                        )}
                                                        &nbsp;
                                                        {!hideOperations && !type && <CellCallToContactEdit phone={phone} />}
                                                    </b>
                                                </TableCell>
                                                <TableCell>{tags_ids && <ListTagsPopup tags_ids={tags_ids} />}</TableCell>
                                            </Fragment>
                                        )}
                                        {!showSource && (
                                            <TableCell>
                                                {sourceTitle && <Icon name="database" color={color} title={sourceTitle} />}
                                                <Icon
                                                    name={
                                                        direction === null
                                                            ? 'tty'
                                                            : direction === CALL_TYPE_INCOMING
                                                            ? 'arrow right'
                                                            : 'arrow left'
                                                    }
                                                    title={
                                                        direction === null
                                                            ? 'Просроченный звонок'
                                                            : direction === CALL_TYPE_INCOMING
                                                            ? 'Входящий звонок'
                                                            : 'Исходящий звонок'
                                                    }
                                                    color={
                                                        direction === null ? 'black' : direction === CALL_TYPE_INCOMING ? 'green' : 'red'
                                                    }
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            {major_user && (
                                                <Fragment>
                                                    <UserLinkWithPreview user={major_user} />
                                                    {!hideOperations && (
                                                        <Dropdown item icon="setting" inline>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    onClick={cellCallStore.pushUserToFilter.bind(
                                                                        cellCallStore,
                                                                        major_user.user_id
                                                                    )}
                                                                >
                                                                    <Icon name="search" />
                                                                    Добавить в фильтр
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                                </Fragment>
                                            )}
                                            {!major_user && abonent}
                                        </TableCell>
                                        <TableCell>
                                            <CallStatus
                                                answerTime={answerTime}
                                                releaseTime={releaseTime}
                                                call_id={call_id}
                                                externalId={externalId}
                                                outer_id={outer_id}
                                                downloadLink={downloadLink}
                                                startTime={startTime}
                                                assignUser={assignUser}
                                                direction={direction}
                                                reason={reason}
                                                hasListened={hasListened}
                                                major_user_id={major_user_id}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {showSource && comment && (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <b>Комментарий к звонку: </b>
                                                {comment}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Fragment>
                            );
                        }
                    )}
                </TableBody>
            </Fragment>
        );
    }
}

export default CellCallTableBody;
