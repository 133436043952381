import React, { Fragment, useState, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import commonStore from '~/stores/commonStore';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Popover from '@material-ui/core/Popover';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import { InfoMessage, ErrorMessage, WarningMessage, MessageTitle } from '~ui/Message';
import Progress from '~ui/Progress';

import OutsideClick from '~/components/Base/OutsideClick';
import whatsappStore from '~/stores/whatsappStore';
import authStore from '~/stores/authStore';

import HeaderAnimatedIconButton from '~/components/Header/Notifications/HeaderAnimatedIconButton';
import { adjustPhoneNumber } from '~/components/Base/PhonesList';
import Modal from '~ui/Modal';

const WhatsappQrCode = ({ QRCode }: { QRCode: string }) => {
    const sizePx = 264;

    return (
        <>
            {/*<InfoMessage>*/}
            {/*    <MessageTitle>QR код для подключения WhatsApp</MessageTitle>*/}
            {/*</InfoMessage>*/}
            <div
                style={{
                    background: `url('${QRCode}') center no-repeat`,
                    width: sizePx,
                    minWidth: sizePx,
                    maxWidth: sizePx,
                    height: sizePx,
                    minHeight: sizePx,
                    maxHeight: sizePx,
                    margin: '0 auto'
                }}
            />
            <InfoMessage style={{ maxWidth: '450px' }}>
                <p>
                    • Откройте WhatsApp на вашем мобильном устройстве. Перейдите в настройки WhatsApp и выберите «Связанные устройства» (или
                    «Linked Devices»). Нажмите на кнопку «Добавить устройство» и отсканируйте QR-код, отображаемый в вашей CRM.
                </p>
                <p>
                    • После успешного сканирования система автоматически подключит ваш WhatsApp к CRM. Вы увидите уведомление о завершении.
                </p>
            </InfoMessage>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            backgroundColor: theme.palette.error.dark
        },
        message: {
            display: 'flex',
            alignItems: 'center'
        },
        icon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(1)
        }
    })
);

const WhatsappError = ({ error }: { error: string }) => {
    const classes = useStyles();

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open
            autoHideDuration={6000}
        >
            <SnackbarContent
                className={classes['error']}
                message={
                    <span className={classes.message}>
                        <ErrorIcon className={classes.icon} />
                        {error}
                    </span>
                }
            />
        </Snackbar>
    );
};

const HeaderWhatsappStatus = () => {
    const [$triggerRef, setRef] = useState<HTMLElement | null>(null);
    const [popupIsOpen, setOpenPopup] = useState(false);
    const toggleOpenPopup = () => {
        setOpenPopup(!popupIsOpen);
    };

    const { whatsappQRCode, whatsappStatus, whatsappConnectedPhone, whatsappError } = whatsappStore;

    useEffect(() => {
        if (whatsappStatus === 'qr' && !commonStore.isMobile) {
            setOpenPopup(true);
        }
    }, [whatsappStatus]);

    // null выводим после useState, иначе может быть ошибка
    if (commonStore.isMobile || !authStore.currentUser.whatsappIsEnable) {
        return null;
    }

    let WAElement: ReactElement = null;

    switch (whatsappStatus) {
        case 'init':
            WAElement = (
                <ErrorMessage header="WhatsApp">
                    Ожидание qr кода <Progress show linear color="secondary" />
                </ErrorMessage>
            );
            break;
        case 'qr':
            WAElement = <WhatsappQrCode QRCode={whatsappQRCode} />;
            break;
        case 'ready':
            WAElement = <InfoMessage header="WhatsApp">Подключен номер {adjustPhoneNumber(whatsappConnectedPhone || '')}</InfoMessage>;
            break;
        case 'phoneNotConnected':
            WAElement = <InfoMessage header="WhatsApp">Телефон с WhatsApp не подключен к интернет</InfoMessage>;
            break;
        case 'authInProgress':
            WAElement = (
                <InfoMessage header="WhatsApp">
                    Подключение к WhatsApp
                    <Progress show linear color="secondary" />
                </InfoMessage>
            );
            break;
        case 'phoneNotMatched':
            WAElement = (
                <ErrorMessage header="WhatsApp">
                    Номер {adjustPhoneNumber(whatsappConnectedPhone || '')} не соответсвует привязанному к вашему профилю
                </ErrorMessage>
            );
            break;
        case 'disconnect':
            WAElement = <WarningMessage header="WhatsApp">Пропало соединение с Whatsapp</WarningMessage>;
            break;
        default:
            const error: never = whatsappStatus;
    }

    if (whatsappStatus === 'qr' && popupIsOpen && !commonStore.isMobile) {
        return (
            <Modal onClose={toggleOpenPopup} header="QR код для подключения WhatsApp">
                {WAElement}
            </Modal>
        );
    }

    return (
        <>
            {whatsappError && <WhatsappError error={whatsappError} />}

            <HeaderAnimatedIconButton
                defaultColor={whatsappStatus === 'qr' ? 'secondary' : 'primary'}
                count={whatsappQRCode ? '!' : 0}
                icon={<WhatsAppIcon />}
                onToggle={toggleOpenPopup}
                setRef={setRef}
            />

            <Popover
                open={popupIsOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                anchorEl={$triggerRef}
            >
                <OutsideClick onOutsideClick={toggleOpenPopup} style={{ padding: '1em' }}>
                    <div>{WAElement}</div>
                </OutsideClick>
            </Popover>
        </>
    );
};

export default observer(HeaderWhatsappStatus);
