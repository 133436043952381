import React, { useState } from 'react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';

import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';

import ContactCallsMode from '~/components/Items/Contact/ContactCallsMode';
import EstateIncomingCallsMode from './EstateIncomingCallsMode';
import EstateAdsChatsMode from './EstateAdsChatsMode';
import LoaderAwait from '~/components/Base/LoaderAwait';

const TAB_INCOMING_CALLS = 'incomingCalls';
const TAB_OWNER_CALLS = 'ownerCalls';

type EstateCallsModeProps = {
    estate_id: number;
};

type EstateCallsTab = 'incomingCalls' | 'ownerCalls' | 'messages';

const EstateCallsMode = ({ estate_id }: EstateCallsModeProps) => {
    const [activeTab, setActiveTab] = useState<EstateCallsTab>('incomingCalls');

    const estate = estateStore.getItem(estate_id).item;
    if (!estate) {
        return <LoaderAwait />;
    }

    return (
        <>
            <Tabs
                value={activeTab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={(_, tab) => setActiveTab(tab)}
                style={{ height: '36px' }}
            >
                <Tab
                    label={
                        <Badge badgeContent={estate.callsCount} color="secondary">
                            <Box display="flex" style={{ gap: '0.5rem', alignItems: 'center' }}>
                                <PhoneCallbackIcon />
                                <div>Входящие по объекту</div>
                            </Box>
                        </Badge>
                    }
                    value="incomingCalls"
                />
                <Tab
                    label={
                        <Badge badgeContent={estate.adsMessagesCount} color="secondary">
                            <Box display="flex" style={{ gap: '0.5rem', alignItems: 'center' }}>
                                <QuestionAnswerIcon />
                                <div>Сообщения с площадок</div>
                            </Box>
                        </Badge>
                    }
                    value={'messages'}
                />
                <Tab
                    label={
                        <Box display="flex" style={{ gap: '0.5rem', alignItems: 'center' }}>
                            <PhoneForwardedIcon />
                            <div>Звонки Собственнику (Продавцу)</div>
                        </Box>
                    }
                    value="ownerCalls"
                    style={{ maxWidth: '400px' }}
                />
            </Tabs>

            <Box mt={1}>
                {activeTab === TAB_OWNER_CALLS && estate.contact_id && <ContactCallsMode contact_id={estate.contact_id} />}
                {activeTab === 'messages' && <EstateAdsChatsMode estate_id={estate_id} />}
                {activeTab === TAB_INCOMING_CALLS && <EstateIncomingCallsMode estate_id={estate_id} />}
            </Box>
        </>
    );
};

export default observer(EstateCallsMode);
