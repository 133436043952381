import React from 'react';
import { observer } from 'mobx-react';

import commonStore from '~/stores/commonStore';
import { CellCallMetadata } from '~/stores/helpers/cellCalls.helpers';
import useApiFetch from '~/stores/hooks/useApiFetch';
import * as callRatesApi from '~/api/cell/callRatesApi';

import List from '@material-ui/core/List';

import { Modal, ModalContent } from '~ui/Modal';
import Divider from '~ui/Divider';
import ListError from '~/components/ListError';

import ListenOrDownloadBox from './ListenOrDownloadBox';
import CallListensItem from './CallListensItem';

const CallListensModal = ({ callMetadata, onClose }: { callMetadata: CellCallMetadata; onClose: () => void }) => {
    const { data, loading, error } = useApiFetch(
        callRatesApi.fetchList(100, 0, 'call_rate_id', 'descending', { call_id: callMetadata.call_id })
    );

    return (
        <Modal onClose={!loading ? onClose : null} fullScreen={commonStore.isMobile} fullWidth maxWidth="sm" header="Оценка звонка">
            <ModalContent dividers>
                <ListenOrDownloadBox callMetadata={callMetadata} showDownloadLink={false} />
                <Divider />
                {error && <ListError errors={[error]} />}
                {data?.list && (
                    <List>
                        {data.list.map(callRate => (
                            <CallListensItem key={callRate.call_rate_id} callRate={callRate} />
                        ))}
                    </List>
                )}
            </ModalContent>
        </Modal>
    );
};

export default observer(CallListensModal);
