import React from 'react';
import { observer } from 'mobx-react';

import { CallRate } from '~/types/callRates.types';
import commonStore from '~/stores/commonStore';

import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import UserAvatar from '~ui/UserAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';

const CommentItemText = ({ title, comment }: { title: string; comment: string }) => (
    <p>
        <Typography component="span" variant="body2" color="textPrimary">
            {title}
        </Typography>
        {` — ${comment}`}
    </p>
);

const CallListensItem = ({ callRate }: { callRate: CallRate }) => {
    const { user, rate, improvementSuggestions, positiveFeedback, comment } = callRate;

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <UserAvatar
                    firstName={user.firstName}
                    lastName={user.lastName}
                    src={user.avatarUrl}
                    size={commonStore.isMobile ? 36 : 48}
                />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
                        <UserLinkWithPreview user={user} icon={false} />
                        {rate && <Rating value={rate} max={5} readOnly />}
                    </Box>
                }
                secondary={
                    <>
                        {positiveFeedback && <CommentItemText title="Понравилось" comment={positiveFeedback} />}
                        {improvementSuggestions && <CommentItemText title="Можно улучшить" comment={improvementSuggestions} />}
                        {comment && <CommentItemText title="Комментарий" comment={comment} />}
                    </>
                }
            />
        </ListItem>
    );
};

export default observer(CallListensItem);
