import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import userStatisticsStore from '~/stores/userStatisticsStore';
import UserStatisticsCharts from './UserStatisticsCharts';

import ListError from '~/components/ListError';

import Grid from '@material-ui/core/Grid';
import Progress from '~ui/Progress';
import { WarningMessage, MessageTitle } from '~ui/Message';

const UserStatistics = ({ user_id }: { user_id: number }) => {
    const [loadingOptions, setLoadingOptions] = useState(true);

    useMemo(() => {
        userStatisticsStore.fetchItemList(user_id).then(() => {
            userStatisticsStore.fetchOptions(user_id).then(() => setLoadingOptions(false));
        });
    }, []);

    const { loadingList, errors, list, options } = userStatisticsStore.getItemList(user_id);
    const kpiOptionsFound = Boolean(options?.userKpis?.length);

    return (
        <>
            <ListError errors={errors} />
            <Progress show={loadingList || loadingOptions} />

            {!loadingOptions && !kpiOptionsFound && (
                <WarningMessage>
                    <MessageTitle>Истории kpi не найдено</MessageTitle>
                    Возможно, не был создан ни один kpi для данного сотрудника
                </WarningMessage>
            )}

            {kpiOptionsFound && (
                <Grid container spacing={3}>
                    <UserStatisticsCharts user_id={user_id} />
                </Grid>
            )}
        </>
    );
};

export default observer(UserStatistics);
